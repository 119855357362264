import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../AuthContext";
import ChangePassword from "@components/changePassword/ChangePasswordComponent";
import FlyoutComponent from "@components/flyout/FlyoutComponent";

import Crown from "@resources/images/crown.svg";
import EditGold from "@resources/images/edit-pic_gold.svg";
import EditPink from "@resources/images/edit-pic_pink.svg";
import EditGreen from "@resources/images/edit-pic.svg";
import Add from "@resources/images/add.svg";
import ModalPopupComponent from "../../../components/modalPopup/ModalPopupComponent";

export default function ProfileForm({ onProfileUpdate }) {
    const { user, setUser, saveProfile, uploadProfileImage, getShortName } = useContext(AuthContext);

    const [previousData] = useState({});
    const [profileType, setProfileType] = useState("");
    const [showProgress, setShowProgress] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
    const [addImage, setAddImage] = useState("");
    const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] = useState(false);
    const [profileImage, setProfileImage] = useState(undefined);
    const imageUploadRef = useRef();

    const showFileDialog = () => {
        imageUploadRef.current.click();
    };

    useEffect(() => {
        setProfileType(getProfileType());
    }, [user?.role]);

    useEffect(() => {
        setProfileImage(user?.avatarURL);
    }, [user?.avatarIdentifier]);

    const onFileChange = (e) => {
        const data = e.target.files;
        if (e.target.files[0] != null) {
            setShowProgress(true);
            uploadProfileImage(data[0])
                .then((resp) => {
                    successState();
                    setUser({
                        ...user,
                        avatarIdentifier: resp.data.avatarIdentifier,
                        avatarURL: resp.data.avatarURL
                    });
                })
                .catch(() => errorState());
        }
    };

    const saveCurrentProfile = () => {
        if (validateForm()) {
            setShowProgress(true);
            saveProfile(user)
                .then((response) => {
                    if (response) {
                        if (onProfileUpdate) {
                            onProfileUpdate();
                        }
                        successState();
                    } else {
                        errorState();
                    }
                })
                .catch(() => errorState());
        }
    };

    const successState = () => {
        setShowSuccess(true);
        setShowError(false);
        setShowProgress(false);
        setShowChangePasswordDialog(false);
    };

    const errorState = () => {
        setShowSuccess(false);
        setShowError(true);
        setShowChangePasswordDialog(false);
        setShowProgress(false);
    };

    const validateForm = () => {
        return isFullNameValid() && isEmailValid();
    };

    const savePassword = () => {
        setShowSuccess(true);
        setShowError(false);
        setShowChangePasswordDialog(false);
    };

    const isFullNameValid = () => {
        return user.firstName?.length > 0 && user.lastName?.length > 0;
    };

    const isEmailValid = () => {
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            user.email
        );
    };

    const isProfileChanged = () => {
        return (
            previousData?.firstName !== user?.firstName ||
            previousData?.lastName !== user?.lastName ||
            previousData?.email !== user?.email ||
            previousData?.primaryPhoneNumber !== user?.primaryPhoneNumber
        );
    };

    const flyoutOnClose = () => {
        setShowSuccess(false);
        setShowError(false);
    };

    const deleteAccount = () => {
        setShowError(true);
        setShowSuccess(false);
        setShowDeleteAccountConfirmation(false);
    };

    const closeShowPasswordDialog = () => {
        setShowChangePasswordDialog(false);
        setShowError(false);
    };

    const closeDeleteAccountPopup = () => {
        setShowError(false);
        setShowDeleteAccountConfirmation(false);
    };

    const handleChange = ({ target }) => {
        target.value = target.value.trimStart().replace(/\s\s+/g, " ");
        setUser({
            ...user,
            [target.name]: target.value
        });
    };
    const getProfileType = () => {
        switch (user?.role) {
            case "PARENT":
                setAddImage(EditGold);
                return "parent";
            case "SURROGATE":
                setAddImage(EditPink);
                return "surrogate";
            case "EGG_DONOR":
                setAddImage(EditGreen);
                return "egg_donor";
            default:
                return "egg_donor";
        }
    };

    const isFormValid = () => {
        return isProfileChanged() ? validateForm() : false;
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        saveCurrentProfile();
    };

    return (
        <div className="profile-container">
            {showProgress && <div className={`animated-gradient-${profileType}`} />}
            {showSuccess && (
                <FlyoutComponent type="success" message="Profile has been successfully saved" onClose={() => flyoutOnClose()} />
            )}
            {showError && (
                <FlyoutComponent
                    type="error"
                    message="An error occured while try to process your request"
                    onClose={() => flyoutOnClose()}
                />
            )}
            {showChangePasswordDialog && <ChangePassword onSave={() => savePassword()} onDialogClose={() => closeShowPasswordDialog()} />}
            {showDeleteAccountConfirmation && (
                <ModalPopupComponent title="Delete Account" className="delete-account-modal" onDialogClose={closeDeleteAccountPopup}>
                    <div className="deleteAccountContainer">
                        Are you sure you want to delete your account?
                        <div>
                            <input type="button" value="Confirm" className="button" onClick={deleteAccount} />
                            <input type="button" value="Cancel" className="button" onClick={closeDeleteAccountPopup} />
                        </div>
                    </div>
                </ModalPopupComponent>
            )}
            <div className="profile-form">
                <div>
                    <div>
                        <img src={Crown} alt="" />
                    </div>
                    <input
                        id="upload"
                        ref={imageUploadRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            onFileChange(e);
                        }}
                        className="fileUploader"
                    />
                    <div className="profile-image" onClick={showFileDialog}>
                        <img src={profileImage ? addImage : Add} className="add-image" alt="" />
                        {profileImage ? <img className="user-avatar" src={profileImage} alt="" /> : <span>{getShortName() || ""}</span>}
                    </div>
                </div>
                <form onSubmit={handleFormSubmit} className="form">
                    <div className="form-item">
                        <label htmlFor="lblFirstName">First Name</label>
                        <input type="text" id="lblFirstName" name="firstName" value={user?.firstName} onChange={handleChange} />
                    </div>
                    <div className="form-item">
                        <label htmlFor="lblLastName">Last Name</label>
                        <input type="text" id="lblLastName" name="lastName" value={user?.lastName} onChange={handleChange} />
                    </div>
                    <div className="form-item">
                        <label htmlFor="lblEmail">Email</label>
                        <input type="text" id="lblEmail" name="email" defaultValue={user?.email} onChange={handleChange} disabled />
                    </div>
                    <div className="form-item">
                        <label htmlFor="lblPrimaryPhoneNumber">Primary Phone Number</label>
                        <input
                            type="text"
                            id="lblPrimaryPhoneNumber"
                            name="primaryPhoneNumber"
                            defaultValue={user?.primaryPhoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="change-password">
                        <div
                            onClick={() => {
                                setShowChangePasswordDialog(true);
                                setShowSuccess(false);
                                setShowError(false);
                            }}
                        >
                            Change Password
                        </div>
                    </div>

                    {isFormValid() ? (
                        <input type="submit" value="Save Changes" className="save-button" />
                    ) : (
                        <input type="submit" value="Save Changes" className="save-button disabled" />
                    )}

                    <div className="delete-account">
                        <div
                            onClick={() => {
                                setShowDeleteAccountConfirmation(true);
                                setShowSuccess(false);
                                setShowError(false);
                            }}
                        >
                            Delete Account
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
