import React, { useEffect } from "react";
import { Link } from "react-scroll";
import { unansweredQuestions } from "@shared/AnswersTracker/AnswersTracker";
import "./UnansweredNavigationComponent.scss";
import { useTranslation } from "react-i18next";
import { surveyQuestionMethods } from "../../../shared/Questions/Questions";

function UnansweredNavigationComponent({ sectionId, accountType }) {
    const { t } = useTranslation();

    useEffect(() => {
        surveyQuestionMethods.scrollSurveyToBottom();
    }, []);

    const countOfUnansweredQuestions = unansweredQuestions.numberOfUnansweredQuestions(sectionId) || 0;
    const firstUnansweredQuestion = unansweredQuestions.getFirstUnansweredQuestion(sectionId);
    const pluralEnding = countOfUnansweredQuestions > 1 ? "s" : "";
    if (!countOfUnansweredQuestions) {
        return null;
    }

    return (
        <Link
            className={`scroll-link scroll-link-${accountType}`}
            containerId="survey-container"
            to={firstUnansweredQuestion}
            smooth="easeInOutQuart"
        >
            {`${t("You must answer")} ${countOfUnansweredQuestions} ${t("question")}${pluralEnding}`}
        </Link>
    );
}

export default UnansweredNavigationComponent;
