import React, { useState, useEffect, useContext } from "react";
import "./SurveyHeaderComponent.scss";
import Back from "@resources/images/back_arrow_white.svg";
import { withRouter } from "react-router-dom";
import AppService from "@service/AppService/AppService";
import { unansweredQuestions } from "@shared/AnswersTracker/AnswersTracker";
import { NumberAnswerService } from "@service/NumberAnswerService/NumberAnswerService";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import classNames from "classnames";
import SurveyProgressBar from "./SurveyProgressBar/SurveyProgressBar";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";
import { AuthContext } from "../../AuthContext";
import { DynamicQuestionService } from "../../service/DynamicQuestionService/DynamicQuestionService";

function SurveyHeaderComponent({ selectedTopic, className, backRedirect, isLoading }) {
    const authContext = useContext(AuthContext);
    const questionContext = useContext(SurveyQuestionsContext);
    const [_y, setUpdate] = useState(false);

    useEffect(() => {
        setUpdate(false);
        unansweredQuestions.clear();
        setUpdate(true);
        addAllSubSurveyQuestions();
        setUpdate(false);
    }, [AppService.hiddenQuestions, selectedTopic.questions, isLoading]);

    const isQuestionAnswered = (question, section = selectedTopic) => {
        if (question.answers && question.answers.length === 0) {
            return true;
        }

        if (question.optional) {
            unansweredQuestions.addQuestion(section.id || section.sequenceId, question.sequenceId, true);
        }

        switch (question.type) {
            case "TEXT":
            case "UPLOAD":
            case "MULTI_UPLOAD": {
                const filtered = question.answers.filter((a) => {
                    if (a.userAnswer !== undefined) {
                        if (a.inputMask && a.inputMask.mask) {
                            return surveyQuestionMethods.isInputMaskValid(a);
                        } else {
                            if (question?.salesForceField?.fieldName === "Email") {
                                const emailPattern =
                                    /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
                                return emailPattern.test(a.userAnswer);
                            } else {
                                const textPattern = /^[^.\s]/;
                                return a.userAnswer !== "{}" && a.userAnswer.length >= 1 && textPattern.test(a.userAnswer);
                            }
                        }
                    }
                });
                return filtered.length > 0;
            }
            case "DATE":
            case "ADDRESS":
            case "PHONE": {
                if (question.type === "PHONE" && !questionContext?.phoneNumberIsValid) return false;
                return surveyQuestionMethods.checkIfEmpty(question);
            }
            case "NUMBER": {
                return question.answers.every(NumberAnswerService.isAnswerValid);
            }
            default: {
                return (
                    question[surveyQuestionMethods.lastAnswerListName()] && question[surveyQuestionMethods.lastAnswerListName()].length > 0
                );
            }
        }
    };
    const flattenArray = (questions) => {
        return questions.reduce((flat, next) => {
            if (next === null) {
                return flat;
            }

            return flat.concat(Array.isArray(next) ? flattenArray(next) : next);
        }, []);
    };

    const getAnsweredQuestionCount = (progressLines) => {
        let count = 0;
        flattenArray(progressLines).forEach((question) => {
            question.props.className.includes("question-status-answered") && count++;
            return;
        });
        return count;
    };

    const processQuestion = (question, section) => {
        const isQuestionHidden = AppService.hiddenQuestions[section.id]?.includes(question.sequenceId);

        if (isQuestionHidden) {
            unansweredQuestions.removeQuestion(section.id || section.sequenceId, question.sequenceId);
            return null;
        }

        let isAnswered;
        if (question.type === "DYNAMIC_GROUP") {
            isAnswered = DynamicQuestionService.isOneInstanceHasAnswers(question, questionContext, selectedTopic.id);
        } else {
            isAnswered = isQuestionAnswered(question, section);
        }

        if (isAnswered) {
            unansweredQuestions.removeQuestion(section.id || section.sequenceId, question.sequenceId);
        } else if (!question.optional) {
            unansweredQuestions.addQuestion(section.id || section.sequenceId, question.sequenceId);
        }
    };

    const addAllSubSurveyQuestions = () => {
        AppService.parsedQuestions.forEach((section) => {
            if (authContext.user.role === "SURROGATE") {
                AppService.hiddenQuestions[section.id] = AppService.getAllHideQuestionIds(section.questions);
            }

            section.questions.forEach((question) => {
                if (question.type === "NOTE") return;

                if (question.type === "GROUP") {
                    if (authContext.user.role === "SURROGATE") {
                        AppService.hiddenQuestions[section.id].push(...AppService.getAllHideQuestionIds(question.questions));
                    }

                    question.questions.forEach((groupQuestion) => {
                        processQuestion(groupQuestion, section);
                    });
                } else {
                    processQuestion(question, section);
                }
            });
        });
    };

    addAllSubSurveyQuestions();
    const buildProgressLines = () =>
        selectedTopic.questions.map((q, i) => {
            let result = null;
            if (q.type === "NOTE") {
                return result;
            }
            if (q.type === "GROUP") {
                const groupProgress = [];
                q.questions.forEach((gq, gi) => {
                    const isQuestionHidden = AppService.hiddenQuestions[selectedTopic.id]?.find((h) => {
                        return h == gq.sequenceId;
                    });
                    if (isQuestionHidden) {
                        unansweredQuestions.removeQuestion(selectedTopic.id || selectedTopic.sequenceId, gq.sequenceId);
                        return null;
                    }

                    const answered = isQuestionAnswered(gq);
                    if (answered) {
                        unansweredQuestions.removeQuestion(selectedTopic.id || selectedTopic.sequenceId, gq.sequenceId);
                    } else {
                        !gq.optional && unansweredQuestions.addQuestion(selectedTopic.id || selectedTopic.sequenceId, gq.sequenceId);
                    }

                    const classNameGroupAnswered = gq.optional || answered ? "question-status question-status-answered" : "question-status";
                    const key = AppService.Guid();
                    groupProgress.push(<span key={`${key}-${gi}`} className={classNameGroupAnswered} />);
                });
                result = groupProgress;
            } else if (q.type === "DYNAMIC_GROUP") {
                const isQuestionHidden = AppService.hiddenQuestions[selectedTopic.id]?.find((h) => {
                    return h == q.sequenceId;
                });
                if (isQuestionHidden) {
                    unansweredQuestions.removeQuestion(selectedTopic.id || selectedTopic.sequenceId, q.sequenceId);
                    return null;
                }
                const answered = DynamicQuestionService.isOneInstanceHasAnswers(q, questionContext, selectedTopic.id);
                if (answered) {
                    unansweredQuestions.removeQuestion(selectedTopic.id || selectedTopic.sequenceId, q.sequenceId);
                } else {
                    !q.optional && unansweredQuestions.addQuestion(selectedTopic.id || selectedTopic.sequenceId, q.sequenceId);
                }
                const classNameAnswered = q.optional || answered ? "question-status question-status-answered" : "question-status";
                result = <span key={i} className={classNameAnswered} />;
            } else {
                const isQuestionHidden = AppService.hiddenQuestions[selectedTopic.id]?.find((h) => {
                    return h == q.sequenceId;
                });

                if (isQuestionHidden) {
                    unansweredQuestions.removeQuestion(selectedTopic.id || selectedTopic.sequenceId, q.sequenceId);
                    return null;
                }

                const answered = isQuestionAnswered(q);
                if (answered) {
                    unansweredQuestions.removeQuestion(selectedTopic.id || selectedTopic.sequenceId, q.sequenceId);
                } else {
                    !q.optional && unansweredQuestions.addQuestion(selectedTopic.id || selectedTopic.sequenceId, q.sequenceId);
                }
                const classNameAnswered = q.optional || answered ? "question-status question-status-answered" : "question-status";
                result = <span key={i} className={classNameAnswered} />;
            }
            return result;
        });
    const progressLines = buildProgressLines();

    const headerClassName = AppService.accountType === "parent" ? "parent-survey-header" : "";
    let translatedTopicTitle = "";
    if (selectedTopic.title) {
        translatedTopicTitle = getTranslatedTextIfNeeded(selectedTopic.title);
    } else if (selectedTopic.name) {
        translatedTopicTitle = getTranslatedTextIfNeeded(selectedTopic.name);
    }

    const allQuestions = flattenArray(progressLines).length;
    const answeredQuestions = getAnsweredQuestionCount(progressLines);

    const buildProgressHeader = () =>
        allQuestions > 25 ? (
            <SurveyProgressBar completed={answeredQuestions} total={allQuestions} />
        ) : (
            <div className="question-statuses">{progressLines}</div>
        );
    const showBackButton = (AppService.accountType && AppService.accountType !== "surrogate") || authContext.isSurveyFinished();
    const hiddenBackButtonClassName = !showBackButton ? "hidden-back-btn" : "";

    return (
        <div className={classNames("survey-header-container", className)}>
            <div className={`survey-questions-header survey-questions-header-desktop ${headerClassName}`}>
                <div className="title">{translatedTopicTitle}</div>
                {buildProgressHeader()}
            </div>
            <div className={`survey-questions-header survey-questions-header-mobile ${headerClassName} ${hiddenBackButtonClassName}`}>
                <div>
                    <div className="title">
                        {showBackButton && (
                            <div className="back-button" onClick={backRedirect}>
                                <img src={Back} alt="" />
                            </div>
                        )}
                        {translatedTopicTitle}
                    </div>
                </div>
                {buildProgressHeader()}
            </div>
        </div>
    );
}

export default withRouter(SurveyHeaderComponent);
