import React, { useEffect, useRef, useState } from "react";
import AppService from "@service/AppService/AppService";
import SurveyHeader from "@components/surveyHeader/SurveyHeaderComponent";
import LiteQuestionList from "../../components/LiteQuestionList/LiteQuestionList";
import LiteSurveyFinishMessage from "./LiteSurveyFinishMessage";
import "./LiteSurvey.scss";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Constants from "../../helper/Constants";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LiteSurvey({ getInternationalized }) {
    const surveyStartRef = useRef(null);
    const [liteSurvey, setLiteSurvey] = useState();
    const [selectedSection, setSelectedSection] = useState();
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
    const [allSubSurveySections, setAllSubSurveySections] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [isLiteSurveyFinished, setIsLiteSurveyFinished] = useState(false);
    const [finishButtonDisable, setFinishButtonDisable] = useState(false);
    const [listOfFinishedSections, setListOfFinishedSections] = useState([]);
    const [destinationPage, setDestinationPage] = useState("default");

    const [countryCode, setCountryCode] = useState(localStorage.getItem("countryCode") || "");

    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;

        const storedCountryCode = localStorage.getItem("countryCode");
        const storedCountryCodeTime = localStorage.getItem("countryCodeTime");
        if (storedCountryCode && storedCountryCodeTime && Date.now() - storedCountryCodeTime < THREE_DAYS_IN_MS) {
            setCountryCode(storedCountryCode);
        } else {
            (async () => await getUserLocation())();
        }
    }, []);

    useEffect(() => {
        if (history.location.pathname !== "/surrogates/register") {
            const extoleScript1 = document.getElementById("extole");
            const extoleScript2 = document.getElementById("second_extole");
            extoleScript1 && document.head.removeChild(extoleScript1);
            extoleScript2 && document.head.removeChild(extoleScript2);
        }
    }, []);

    useEffect(() => {
        AppService.getAxios()
            .get(`/lite-survey/SURROGATE`)
            .then((response) => {
                const survey = response.data;
                if (!query.get("destination")) {
                    getInternationalized && getInternationalized(survey.internationalizationSupport);
                }
                setLiteSurvey(survey);
                processSections(survey);
            })
            .catch((e) => console.log(e));

        setDestinationPage(query.get("destination") ? query.get("destination") : "default");
    }, []);

    useEffect(() => {
        surveyStartRef.current?.scroll(0, 0);
    }, [selectedSectionIndex]);

    const getUserLocation = async () => {
        try {
            const ipResponse = await axios.get("https://ipinfo.io/ip");
            const userIp = ipResponse.data;

            const locationResponse = await axios.get(`${Constants.REST_API_URL}api/validation/location?ip=${userIp}`);
            const countryCode = locationResponse.data.country_code;

            localStorage.setItem("countryCode", countryCode);
            localStorage.setItem("countryCodeTime", Date.now());
            setCountryCode(countryCode);
        } catch (error) {
            console.error("An error occurred while fetching the location: ", error);
        }
    };

    const processSections = (survey) => {
        const updatedSubSurveysSections = [];
        const questionList = [];
        survey.sections.forEach((section) => {
            const container = {
                title: section.name,
                id: section.sequenceId,
                estimate: "",
                questions: []
            };

            section.questions.forEach((question) => {
                const questionItem = {};
                Object.keys(question).forEach((k) => {
                    questionItem[k] = question[k];
                });
                questionList.push(questionItem);
                container.questions.push(questionItem);
            });

            updatedSubSurveysSections.push(container);
        });

        setAllQuestions(questionList);
        setAllSubSurveySections(updatedSubSurveysSections);
        setSelectedSection(updatedSubSurveysSections[selectedSectionIndex]);
    };

    const showFinishMessage = () => {
        if (countryCode !== "US") {
            return <p>We apologize. Registration is not available for your region.</p>;
        } else if (!isLiteSurveyFinished) {
            return (
                <>
                    <SurveyHeader className="progress-lite-survey-header" selectedTopic={selectedSection} />
                    <LiteQuestionList
                        className={destinationPage}
                        sectionCount={allSubSurveySections.length}
                        liteSurvey={liteSurvey}
                        selectedSection={selectedSection}
                        allSubSurveySections={allSubSurveySections}
                        selectedSectionIndex={selectedSectionIndex}
                        allQuestions={allQuestions}
                        setSelectedSection={setSelectedSection}
                        setSelectedSectionIndex={setSelectedSectionIndex}
                        setIsLiteSurveyFinished={setIsLiteSurveyFinished}
                        finishButtonDisable={finishButtonDisable}
                        setFinishButtonDisable={setFinishButtonDisable}
                        listOfFinishedSections={listOfFinishedSections}
                        setListOfFinishedSections={setListOfFinishedSections}
                    />
                </>
            );
        } else {
            return <LiteSurveyFinishMessage />;
        }
    };

    return (
        <>
            {countryCode && selectedSection ? (
                <div className="lite-wrapper surrogate" ref={surveyStartRef}>
                    {showFinishMessage()}
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default LiteSurvey;
