import AppService from "../AppService/AppService";

export const BmiDisqualificationService = {
    isSurveyDisqualifiedByBmi(questions, bmiDisqualificationRule) {
        if (!bmiDisqualificationRule) {
            return false;
        }
        const bmi = this.tryCalculateBmi(questions);
        if (bmi === null) {
            return false;
        }

        return (
            (bmiDisqualificationRule.from && bmi < bmiDisqualificationRule.from) ||
            (bmiDisqualificationRule.to && bmi > bmiDisqualificationRule.to)
        );
    },

    tryCalculateBmi(questions) {
        const heightFt = this.findUserAnswerBySalesforceField(questions, "Height_Ft__c");
        const heightIn = this.findUserAnswerBySalesforceField(questions, "Height_In__c");
        const weightLbs = this.findUserAnswerBySalesforceField(questions, "Weight_Lbs__c");
        if (Number.isInteger(heightFt) && Number.isInteger(heightIn) && Number.isInteger(weightLbs)) {
            return this.calculateBmiValue(heightFt, heightIn, weightLbs);
        }
        return null;
    },

    findUserAnswerBySalesforceField(questions, salesforceFieldName) {
        let sfValue = questions
            .filter((q) => q.salesForceField?.fieldName === salesforceFieldName)
            .map((q) => q.answers[0].userAnswer)
            .map((a) => Number.parseInt(a))[0];
        if (!sfValue && sfValue !== 0) {
            sfValue = questions
                .flatMap((q) => {
                    if (q.type === "GROUP") {
                        return q.questions.flatMap((gQ) => gQ.answers);
                    }
                    return q.answers;
                })
                .filter((a) => a.salesForceField?.fieldName === salesforceFieldName)
                .map((a) => Number.parseInt(a.userAnswer))[0];
        }
        return sfValue;
    },

    calculateBmiValue(heightFt, heightIn, weightLbs) {
        const bmi = (703 * weightLbs) / ((heightFt * 12 + heightIn) * (heightFt * 12 + heightIn));
        return bmi.toFixed(2);
    },

    calculateBmiScore(parsedQuestions, bmiScore) {
        if (!bmiScore || bmiScore.length === 0) {
            return 0;
        }
        let bmiValue = null;
        parsedQuestions.forEach((section) => {
            if (this.tryCalculateBmi(section.questions) !== null) {
                bmiValue = this.tryCalculateBmi(section.questions);
            }
        });
        return AppService.calculateScore(bmiScore, bmiValue);
    }
};
