const PROD_URLS = {
    REST_API_URL: "https://api.conceiveabilities.com/",
    PORTAL_URL: "https://www.conceiveabilities.com/",
    SURVEY_URL: "https://www.conceiveabilities.com",
    SIGN_UP_URLS: {
        SURROGATE: "https://www.conceiveabilities.com/app/surrogates/register",
        PARENT: "https://www.conceiveabilities.com/app/parents/register",
        EGG_DONOR: "https://www.conceiveabilities.com/app/egg-donors/register"
    },
    EGG_DONOR_PROFILES_URL: "https://www.conceiveabilities.com/app/login"
};

const STAGING_URLS = {
    REST_API_URL: "https://staging-api.conceiveabilities.com/",
    PORTAL_URL: "https://www.staging.conceiveabilities.com/",
    SURVEY_URL: "https://www.staging.conceiveabilities.com",
    SIGN_UP_URLS: {
        SURROGATE: "https://www.staging.conceiveabilities.com/app/surrogates/register",
        PARENT: "https://www.staging.conceiveabilities.com/app/parents/register",
        EGG_DONOR: "https://www.staging.conceiveabilities.com/app/egg-donors/register"
    },
    EGG_DONOR_PROFILES_URL: "https://www.staging.conceiveabilities.com/app/login"
};

const UAT_URLS = {
    REST_API_URL: "https://uat-api.conceiveabilities.com/",
    PORTAL_URL: "https://uat-survey.conceiveabilities.com/",
    SURVEY_URL: "https://uat-survey.conceiveabilities.com",
    SIGN_UP_URLS: {
        SURROGATE: "https://uat-survey.conceiveabilities.com/app/surrogates/register",
        PARENT: "https://uat-survey.conceiveabilities.com/app/parents/register",
        EGG_DONOR: "https://uat-survey.conceiveabilities.com/app/egg-donors/register"
    },
    EGG_DONOR_PROFILES_URL: "https://uat-survey.conceiveabilities.com/app/login"
};

export const States = [
    { title: "Alabama", name: "AL" },
    { title: "Alaska", name: "AK" },
    { title: "Arizona", name: "AZ" },
    { title: "Arkansas", name: "AR" },
    { title: "California", name: "CA" },
    { title: "Colorado", name: "CO" },
    { title: "Connecticut", name: "CT" },
    { title: "Delaware", name: "DE" },
    { title: "Florida", name: "FL" },
    { title: "Georgia", name: "GA" },
    { title: "Hawaii", name: "HI" },
    { title: "Idaho", name: "ID" },
    { title: "Illinois", name: "IL" },
    { title: "Indiana", name: "IN" },
    { title: "Iowa", name: "IA" },
    { title: "Kansas", name: "KS" },
    { title: "Kentucky", name: "KY" },
    { title: "Louisiana", name: "LA" },
    { title: "Maine", name: "ME" },
    { title: "Maryland", name: "MD" },
    { title: "Massachusetts", name: "MA" },
    { title: "Michigan", name: "MI" },
    { title: "Minnesota", name: "MN" },
    { title: "Mississippi", name: "MS" },
    { title: "Missouri", name: "MO" },
    { title: "Montana", name: "MT" },
    { title: "Nebraska", name: "NE" },
    { title: "Nevada", name: "NV" },
    { title: "New Hampshire", name: "NH" },
    { title: "New Jersey", name: "NJ" },
    { title: "New Mexico", name: "NM" },
    { title: "New York", name: "NY" },
    { title: "North Carolina", name: "NC" },
    { title: "North Dakota", name: "ND" },
    { title: "Ohio", name: "OH" },
    { title: "Oklahoma", name: "OK" },
    { title: "Oregon", name: "OR" },
    { title: "Pennsylvania", name: "PA" },
    { title: "Rhode Island", name: "RI" },
    { title: "South Carolina", name: "SC" },
    { title: "South Dakota", name: "SD" },
    { title: "Tennessee", name: "TN" },
    { title: "Texas", name: "TX" },
    { title: "Utah", name: "UT" },
    { title: "Vermont", name: "VT" },
    { title: "Virginia", name: "VA" },
    { title: "Washington", name: "WA" },
    { title: "West Virginia", name: "WV" },
    { title: "Wisconsin", name: "WI" },
    { title: "Wyoming", name: "WY" },
    { title: "District of Columbia", name: "DC" }
];

export const UserSurveyStatus = {
    COMPLETED: "COMPLETED",
    MAIN_IN_PROGRESS: "MAIN_IN_PROGRESS",
    REJECTED: "REJECTED",
    INITIAL_REJECTED: "INITIAL_REJECTED",
    ACTIVE_LIVE: "ACTIVE_LIVE",
    MATCHED: "MATCHED",
    IN_SCREENING: "IN_SCREENING",
    COMPLETED_INITIAL: "COMPLETED_INITIAL",
    STARTED: "STARTED"
};

const Constants = {
    REST_API_URL:
        process.env.REACT_APP_ENV === "production"
            ? PROD_URLS.REST_API_URL
            : process.env.REACT_APP_ENV === "uat"
            ? UAT_URLS.REST_API_URL
            : STAGING_URLS.REST_API_URL,
    SIGN_UP_URLS: {
        SURROGATE:
            process.env.REACT_APP_ENV === "production"
                ? PROD_URLS.SIGN_UP_URLS.SURROGATE
                : process.env.REACT_APP_ENV === "uat"
                ? UAT_URLS.SIGN_UP_URLS.SURROGATE
                : STAGING_URLS.SIGN_UP_URLS.SURROGATE,
        PARENT:
            process.env.REACT_APP_ENV === "production"
                ? PROD_URLS.SIGN_UP_URLS.PARENT
                : process.env.REACT_APP_ENV === "uat"
                ? UAT_URLS.SIGN_UP_URLS.PARENT
                : STAGING_URLS.SIGN_UP_URLS.PARENT,
        EGG_DONOR:
            process.env.REACT_APP_ENV === "production"
                ? PROD_URLS.SIGN_UP_URLS.EGG_DONOR
                : process.env.REACT_APP_ENV === "uat"
                ? UAT_URLS.SIGN_UP_URLS.EGG_DONOR
                : STAGING_URLS.SIGN_UP_URLS.EGG_DONOR
    },
    EGG_DONOR_PROFILES_URL:
        process.env.REACT_APP_ENV === "production"
            ? PROD_URLS.EGG_DONOR_PROFILES_URL
            : process.env.REACT_APP_ENV === "uat"
            ? UAT_URLS.EGG_DONOR_PROFILES_URL
            : STAGING_URLS.EGG_DONOR_PROFILES_URL,
    PORTAL_URL:
        process.env.REACT_APP_ENV === "production"
            ? PROD_URLS.PORTAL_URL
            : process.env.REACT_APP_ENV === "uat"
            ? UAT_URLS.PORTAL_URL
            : STAGING_URLS.PORTAL_URL,
    SURVEY_URL:
        process.env.REACT_APP_ENV === "production"
            ? PROD_URLS.SURVEY_URL
            : process.env.REACT_APP_ENV === "uat"
            ? UAT_URLS.SURVEY_URL
            : STAGING_URLS.SURVEY_URL,
    SURVEY_FINISHED_FEEDBACK: {
        title: "Congratulations!",
        text: "You have finished your survey. We will contact you to confirm your application soon."
    },
    SURVEY_FINISHED_NOT_SUBMITTED_FEEDBACK_1: {
        title: "Congratulations!",
        text: "You have answered all the questions."
    },

    SURVEY_FINISHED_NOT_SUBMITTED_SURROGATE_FEEDBACK_1: {
        title: "Your first step is complete!",
        text: "Thank you for applying to be a surrogate, and please know that your information is safe with us. No changes are possible to this application, but we will be in touch soon to discuss your qualifications."
    },

    SURVEY_FINISHED_SUBMITTED_AND_REJECTED_SURROGATE_FEEDBACK_1: {
        title: "Your first step is complete!",
        text: "Thank you for applying to be a surrogate, and please know that your information is safe with us. No changes are possible to this application, but we will be in touch soon to discuss your qualifications."
    },

    SURVEY_FINISHED_NOT_SUBMITTED_FEEDBACK_2: {
        title: "Congratulations!",
        text: 'Please click "Finish" to submit the survey!'
    },
    PARDOT_URLS: {
        REGISTRATION: {
            PARENT: "https://info.conceiveabilities.com/l/309131/2020-03-18/23m14c",
            SURROGATE: "https://info.conceiveabilities.com/l/309131/2020-03-18/23m14f",
            EGG_DONOR: "https://info.conceiveabilities.com/l/309131/2020-03-18/23m14h"
        },
        PARENT: "https://info.conceiveabilities.com/l/309131/2020-03-03/23jzr5",
        SURROGATE: "https://info.conceiveabilities.com/l/309131/2020-03-03/23jzr7",
        EGG_DONOR: "https://info.conceiveabilities.com/l/309131/2020-03-03/23jzr9"
    },
    PUBLIC_AWS_KEY: () => {
        switch (process.env.REACT_APP_ENV) {
            case "production":
                return process.env.REACT_APP_AWS_PRODUCTION_PUBLIC_KEY;
            case "staging":
                return process.env.REACT_APP_AWS_STAGING_PUBLIC_KEY;
            case "uat":
                return process.env.REACT_APP_AWS_UAT_PUBLIC_KEY;
            default:
                return process.env.REACT_APP_AWS_LOCAL_PUBLIC_KEY;
        }
    },

    PRIVATE_AWS_KEY: () => {
        switch (process.env.REACT_APP_ENV) {
            case "production":
                return process.env.REACT_APP_AWS_PRODUCTION_PRIVATE_KEY;
            case "staging":
                return process.env.REACT_APP_AWS_STAGING_PRIVATE_KEY;
            case "uat":
                return process.env.REACT_APP_AWS_UAT_PRIVATE_KEY;
            default:
                return process.env.REACT_APP_AWS_LOCAL_PRIVATE_KEY;
        }
    },

    BUCKET_NAME: () => {
        switch (process.env.REACT_APP_ENV) {
            case "production":
                return process.env.REACT_APP_AWS_PRODUCTION_BUCKET_NAME;
            case "staging":
                return process.env.REACT_APP_AWS_STAGING_BUCKET_NAME;
            case "uat":
                return process.env.REACT_APP_AWS_UAT_BUCKET_NAME;
            default:
                return process.env.REACT_APP_AWS_LOCAL_BUCKET_NAME;
        }
    },
    REGION: process.env.REACT_APP_AWS_REGION
};

export default Constants;
