import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import "./AppHeaderComponent.scss";
import MobileMenu from "@components/mobileMenu/MobileMenuComponent";
import LogoSurrogate from "@resources/images/logo_surrogate.svg";
import LogoWhite from "@resources/images/app-logo-parent.svg";
import AppLogo from "@resources/images/conceiveAbilities-logo.svg";
import GlobeWhite from "@resources/images/globe-white.svg";

import AppService from "@service/AppService/AppService";
import { AuthContext } from "../../AuthContext";

function AppHeaderComponent() {
    const authContext = useContext(AuthContext);
    const [isIntLinksOpened, setIsIntLinksOpened] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const history = useHistory();

    const toggleIntLinksVisibility = () => {
        setShowProfileMenu(false);
        setIsIntLinksOpened(!isIntLinksOpened);
    };

    const toggleProfileMenu = () => {
        setIsIntLinksOpened(false);
        setShowProfileMenu(!showProfileMenu);
    };

    const doLogout = async () => {
        history.push("/login");
        authContext.logout();
    };

    useEffect(() => {
        processProfile();
    }, []);

    const profiles = {
        PARENT: "parent",
        SURROGATE: "surrogate",
        EGG_DONOR: "egg_donor",
        ADMIN: "admin"
    };

    const processProfile = () => {
        const profile = authContext.user;
        if (!profile) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            );
        }
        AppService.accountType = profiles[authContext.user?.role];
        if (authContext.user?.role === "SURROGATE") {
            prepareInitialsFriendBuyData();
        }
    };

    const prepareInitialsFriendBuyData = () => {
        const friendBuy = (window.friendbuy = window.friendbuy || []);
        const included = friendBuy.findIndex((item) => item.includes("site-a55fabc0-conceiveabilities.com"));
        if (included === -1) {
            friendBuy.push(["site", "site-a55fabc0-conceiveabilities.com"]);
            friendBuy.push([
                "track",
                "customer",
                {
                    id: authContext.user?.id,
                    email: authContext.user?.email,
                    first_name: authContext.user?.firstName,
                    last_name: authContext.user?.lastName
                }
            ]);
        }
    };
    AppService.accountType = profiles[authContext.user?.role];

    const globeIcon = GlobeWhite;
    let appLogo = authContext.user?.role === "ADMIN" ? AppLogo : LogoWhite;

    if (AppService.accountType === "surrogate") {
        appLogo = LogoSurrogate;
    }

    return (
        <header className={`app-header header-bg-${AppService.accountType}`}>
            <div className="desktop">
                <div className="left-column">
                    <a href="/app/survey">
                        <img className="app-logo" src={appLogo} alt="ConceiveAbilities" />
                    </a>
                    {/*{AppService.accountType === "surrogate" && <LanguagePicker history={history} />}*/}
                </div>
                <div className="center-column" />
                <div className="right-column">
                    <div className="app-search"></div>
                    {AppService.accountType === "surrogate" ? (
                        <div className="user-profile cursor-default">
                            {authContext.user?.firstName} {authContext.user?.lastName}
                        </div>
                    ) : (
                        <div className="user-profile" onClick={toggleProfileMenu}>
                            {authContext.user?.firstName} {authContext.user?.lastName}
                        </div>
                    )}
                    {showProfileMenu ? (
                        <nav className="profile-menu">
                            <ul>
                                <li>
                                    <Link to="/profile">Edit my profile</Link>
                                </li>
                                <li onClick={() => doLogout()}>Logout</li>
                            </ul>
                        </nav>
                    ) : null}
                    <img src={globeIcon} alt="" className="globe-icon" onClick={toggleIntLinksVisibility} />
                    {isIntLinksOpened ? (
                        <nav className="int-links">
                            <ul>
                                <li>
                                    <a href="https://www.conceiveabilities.com/cn/who-we-are/" target="_blank">
                                        关于我们
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.conceiveabilities.com/fr/qui-sommes-nous/" target="_blank">
                                        Qui sommes-nous?
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.conceiveabilities.com/es/quienes-somos/" target="_blank">
                                        Quiénes Somos
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.conceiveabilities.com/es/programa-de-subrogacion-gestacional/" target="_blank">
                                        Gestacional
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.conceiveabilities.com/es/programa-de-donacion-de-ovulos/" target="_blank">
                                        Óvulos
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.conceiveabilities.com/es/vinculese-con-su-clinica/" target="_blank">
                                        Vincúlese
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    ) : null}
                </div>
            </div>
            <div className="mobile">
                <a href="/app/survey">
                    <img src={appLogo} className="app-logo" alt="ConceiveAbilities" />
                </a>
                {AppService.accountType !== "surrogate" && <MobileMenu />}
            </div>
        </header>
    );
}

export default AppHeaderComponent;
