import React from "react";
import DropDown from "@components/dropDowns/dropDown/DropDownComponent";
import Hint from "@components/hint/HintComponent";
import AppService from "@service/AppService/AppService";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import i18n from "i18next";
import { getFeedback } from "@shared/Feedback";
import { surveyQuestionMethods } from "../../../../shared/Questions/Questions";
import { removeUserAnswerBySfdc } from "../../../../requests/surveyAPIRequests/surveyAPIRequests";
import { removeAdminUserAnswer } from "../../../../requests/AdminSurveyAPIRequests/AdminSurveyAPIRequests";

class AddressQuestionComponent extends React.Component {
    selectedAnswerValue = "";
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            selectedItem: this.prepareUserAnswer()
        };
    }

    prepareUserAnswer() {
        const { question } = this.props;
        if (question.answers && question.answers.length > 0) {
            const firstAnswer = question.answers[0];
            if (firstAnswer.userAnswer) {
                const firstAnswerAddressDetails = JSON.parse(firstAnswer.userAnswer);
                firstAnswer.addressDetails = firstAnswerAddressDetails;
                firstAnswer.isValid = true;
                AppService.updateProgress = true;
                this.selectedAnswerValue = firstAnswerAddressDetails.fullAddress;
                return { text: firstAnswerAddressDetails.fullAddress };
            }
        }
        return { text: "" };
    }

    getAddresses(userAddress) {
        let addresses = ["Address not found"];
        if (userAddress && userAddress.length > 0) {
            AppService.getAxios()
                .get(`/api/google-maps/predictions?input=` + userAddress)
                .then((response) => {
                    const responseData = response.data;
                    if (responseData && responseData.predictions && responseData.predictions.length > 0) {
                        addresses = responseData.predictions.map((prediction) => {
                            return { text: prediction };
                        });
                    }
                    this.setState({ addresses: addresses });
                })
                .catch((error) => {
                    console.error("Address details request failed, reason: ", error);
                    surveyQuestionMethods.showNotificationWithError(`Address details request failed, reason: ${error}`);
                });
        } else {
            this.setState({ addresses: addresses });
        }
    }

    handleChange = (answerValue) => {
        const { selectedTopic, question } = this.props;
        const isAddressesArrayEmpty = this.state.addresses.length === 1 && this.state.addresses[0] === "Address not found";
        if (question.answers && question.answers.length > 0 && !isAddressesArrayEmpty) {
            const firstAnswer = question.answers[0];
            this.setState({ selectedItem: answerValue });

            AppService.getAxios()
                .get(`/api/google-maps/address-details?input=${encodeURIComponent(answerValue.text)}`)
                .then((response) => {
                    firstAnswer.userAnswer = JSON.stringify(response.data);
                    firstAnswer.addressDetails = response.data;
                    firstAnswer.isValid = true;
                    this.selectedAnswerValue = firstAnswer.addressDetails.fullAddress;
                    this.props.onAnswer(selectedTopic, question, firstAnswer);
                })
                .catch((reason) => {
                    console.error("Address details request failed, reason: ", reason);
                    surveyQuestionMethods.showNotificationWithError(reason);
                });
        }
    };

    handleChangeInputText = (value) => {
        const { question } = this.props;
        if (question.answers && question.answers.length > 0) {
            const firstAnswer = question.answers[0];

            firstAnswer.isValid = false;
            AppService.updateProgress = true;

            this.setState({ selectedItem: { text: value } });
            this.getAddresses(value);
        }
        if (question.answers && question.answers[0]?.addressDetails && value.length === 0) {
            question.lastAnswerIds = [];
            this.setState({ selectedItem: { text: "" } });
            if (surveyQuestionMethods.isEdOrIpAccountType()) {
                if (this.props.isDynamicGroup) {
                    this.props.onAnswer(this.props.selectedTopic, question, question.answers[0]);
                    return;
                }
                const removeAnswerRequest = AppService.accountType === "admin" ? removeAdminUserAnswer : removeUserAnswerBySfdc;

                removeAnswerRequest(AppService.surveyId, question.id, question.answers[0]?.salesForceField?.id || null).then(() => {
                    question.answers[0].userAnswer = "";
                    question.answers[0].addressDetails = undefined;
                    this.props.onAnswer(this.props.selectedTopic, question, question.answers[0]);
                });
            } else {
                AppService.getAxios()
                    .delete(`/api/user/answer/${this.props.survey}/remove/address/${question.id}`)
                    .then((response) => {
                        question.answers[0].addressDetails = undefined;
                        question.answers[0].userAnswer = response.data;
                        this.props.onAnswer(this.props.selectedTopic, question, question.answers[0]);
                    })
                    .catch((reason) => console.error("Address removing failed, reason: ", reason));
            }
        }
    };

    render() {
        const { question, showOverlay } = this.props;
        const feedbackItems = [];
        question.answers.map((a) => {
            const feedback = getFeedback(a);
            if (feedback) {
                feedback.forEach((f) => feedbackItems.push(f));
            }
        });
        return (
            <div>
                <div className={`survey-question ${this.props.className}`}>
                    {this.props.readonly && <div className="answer-overlay" />}
                    {showOverlay && <div className="survey-overlay" />}
                    <div className="question-text">{getTranslatedTextIfNeeded(question.text)}</div>

                    <div className="question-answers">
                        <div className="content">
                            <DropDown
                                items={this.state.addresses}
                                selectedItem={this.state.selectedItem}
                                placeholder={i18n.t("Select")}
                                readOnly={false}
                                onChange={this.handleChange}
                                handleChangeInputText={this.handleChangeInputText}
                                isArrowHidden={true}
                            />
                        </div>
                    </div>
                    <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
                </div>
                {feedbackItems}
            </div>
        );
    }
}

export default AddressQuestionComponent;
