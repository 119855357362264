import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from "react-router-dom";
import LiteSurvey from "@pages/liteSurvey/LiteSurvey";
import TransferSurveyRoute from "@helper/TransferSurveyRoute";
import Registration from "@pages/registration/RegistrationComponent";
import Login from "@pages/login/LoginComponent";
import ResetPassword from "@pages/resetPassword/ResetPasswordComponent";
import PasswordRecovery from "@pages/passwordRecovery/PasswordRecoveryComponent";
import PrivateRoute from "@helper/PrivateRoute/PrivateRoute";
import Profile from "@pages/profile/ProfileComponent";
import Account from "@pages/account/AccountComponent";
import PortalRoute from "@helper/PortalRoute/PortalRoute";
import SurveyProfile from "@pages/surveyProfile/SurveyProfile/SurveyProfile";
import FavouriteDonors from "@pages/favoriteDonors/FavouriteDonors";
import FullProfile from "@pages/fullProfile/FullProfile";
import SurveyFinishedPageComponent from "@pages/surveyRejected/SurveyFinishedPageComponent";
import RedirectAuthComponent from "@pages/redirectAuth/RedirectAuthComponent";
import AuthStore from "./AuthContext";
import ReservedDonors from "./pages/ReservedDonors/ReservedDonors";
import QuestionEditor from "./pages/admin/questions/QuestionEditor";
import { UserSurveyStatus } from "./helper/Constants";
import NewSurveyComponent from "./pages/newSurvey/NewSurveyComponent";
import SurveyComponent from "./pages/survey/SurveyComponent";
import AppService from "./service/AppService/AppService";
import { surveyQuestionMethods } from "./shared/Questions/Questions";
import AdminSurveyEditor from "./pages/AdminSurveyEditor/AdminSurveyEditor";
import MaintenancePage from "./pages/maintenancePage/MaintenancePage";
import AdminPrintPdfRedirectComponent from "./pages/adminPrintPdfRedirect/AdminPrintPdfRedirectComponent";

function Routes() {
    const [isEdOrIpUser, setIsEdOrIpUser] = useState(null);

    useEffect(() => {
        if (AppService.accountType) {
            setIsEdOrIpUser(surveyQuestionMethods.isEdOrIpAccountType());
        }
    }, [AppService.accountType]);

    return (
        <AuthStore>
            <Router basename={process.env.PUBLIC_URL}>
                <MaintenanceMode>
                    <div className="App">
                        <Switch>
                            <Route path="/survey/lite">
                                <LiteSurvey />
                            </Route>
                            <Route path="/maintenance">
                                <MaintenancePage />
                            </Route>
                            <Route path="/transfer">
                                <TransferSurveyRoute />
                            </Route>
                            <Route path="/surrogates/register">
                                {/* Internal sign-up for testing React page*/}
                                <Registration page="surrogate" />
                            </Route>
                            <Route path="/parents/register">
                                {/* Internal sign-up for testing React page*/}
                                <Registration page="parent" />
                            </Route>
                            <Route path="/egg-donors/register">
                                {/* Internal sign-up for testing React page*/}
                                <Registration page="egg_donor" />
                            </Route>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/reset">
                                <ResetPassword />
                            </Route>
                            <Route path="/recover/:hash" render={({ match }) => <PasswordRecovery match={match} />} />
                            <Route path="/recover/:hash" render={({ match }) => <PasswordRecovery match={match} />} />
                            <Route path="/parent">
                                <Redirect to="/" />
                                {/*TODO: remove redirect and uncomment when IP role should be delivered*/}
                                {/*<Registration page="parent" />*/}
                            </Route>
                            <Route path="/surrogate">
                                <Redirect to="/" />
                                {/*<Registration page="surrogate" />*/}
                            </Route>
                            <PrivateRoute path="/profile">
                                <Profile />
                            </PrivateRoute>
                            <PrivateRoute path="/start">
                                <Account />
                            </PrivateRoute>
                            <PrivateRoute path="/survey">{isEdOrIpUser ? <NewSurveyComponent /> : <SurveyComponent />}</PrivateRoute>
                            <PrivateRoute path="/survey/:id">
                                <PrivateRoute path="/survey">{isEdOrIpUser ? <NewSurveyComponent /> : <SurveyComponent />}</PrivateRoute>
                            </PrivateRoute>
                            <PortalRoute path="/donors">
                                <SurveyProfile />
                            </PortalRoute>
                            <PortalRoute path="/admin/print/parent/pdf/:integrationId">
                                <AdminPrintPdfRedirectComponent />
                            </PortalRoute>
                            <PortalRoute path="/favorite">
                                <FavouriteDonors />
                            </PortalRoute>
                            <PortalRoute path="/reserved">
                                <ReservedDonors />
                            </PortalRoute>
                            <PortalRoute path="/editor/questions">
                                <QuestionEditor />
                            </PortalRoute>
                            <PortalRoute path="/donor/:integrationId">
                                <FullProfile />
                            </PortalRoute>
                            <PortalRoute path="/donor-survey/:integrationId/:tab">
                                <AdminSurveyEditor />
                            </PortalRoute>
                            <PrivateRoute path="/home" exac>
                                <SurveyFinishedPageComponent status={UserSurveyStatus.REJECTED} />
                            </PrivateRoute>
                            <PrivateRoute path="/accepted" exac>
                                <SurveyFinishedPageComponent status={UserSurveyStatus.COMPLETED} />
                            </PrivateRoute>
                            <Route path="/">
                                <RedirectAuthComponent />
                                {/*TODO: remove redirect and uncomment when other role than GC should be delivered*/}
                            </Route>
                        </Switch>
                    </div>
                </MaintenanceMode>
            </Router>
        </AuthStore>
    );
}

export default Routes;

const MaintenanceMode = ({ children }) => {
    const history = useHistory();

    useEffect(() => {
        const isMaintenance = false;

        if (isMaintenance) {
            history.push("/maintenance");
        }
    }, []);

    return children;
};
