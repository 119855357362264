import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import AppService from "./service/AppService/AppService";
import cloneDeep from "lodash/cloneDeep";
import { UserSurveyStatus } from "./helper/Constants";

export const AuthContext = React.createContext(null);

export default function AuthStore({ children }) {
    const cookies = new Cookies();
    const [user, setUser] = useState(null);
    const [authorized, setAuthorized] = useState(false);
    const [userSurveyStatus, setUserSurveyStatus] = useState(null);

    useEffect(() => {
        if (getToken()) {
            checkAuth();
        }
    }, []);

    useEffect(() => {
        if (user) {
            setUser({
                ...user,
                userSurveyStatus: AppService.userSurveyStatus
            });
            setUserSurveyStatus(AppService.userSurveyStatus);
        }
    }, [AppService.userSurveyStatus]);

    useEffect(() => {
        AppService.isAppStarted = !!user?.questionIndex || !!user?.sectionIndex || !!user?.subSurveyIndex;
    }, [user]);

    const saveToken = (token) => {
        cookies.set("surat", token, {
            path: "/"
        });
    };
    const isSurveyFinished = () => {
        const { REJECTED, COMPLETED, ACTIVE_LIVE, MATCHED, INITIAL_REJECTED } = UserSurveyStatus;

        const finishedStatuses = [REJECTED, COMPLETED, ACTIVE_LIVE, MATCHED, INITIAL_REJECTED];
        return finishedStatuses.includes(userSurveyStatus ? userSurveyStatus : AppService.userSurveyStatus);
    };

    const getToken = () => {
        return cookies.get("surat", {
            path: "/"
        });
    };

    const cleanToken = () => {
        cookies.remove("surat", {
            path: "/"
        });
        cleanUserInfo();
    };

    const cleanUserInfo = () => {
        setUser(null);
        setUserSurveyStatus(null);
        setAuthorized(false);
        AppService.userSurveyStatus = null;
        localStorage.removeItem("uploadedImageName");
    };

    const checkAuth = async () => {
        try {
            const profile = await AppService.getAxios().get(`/api/user`);
            const userData = profile.data;
            userData.authorized = profile.data !== null;
            setUserSurveyStatus(userData.status);
            AppService.userSurveyStatus = userData.status;
            AppService.isAppMuted = userData.muted;
            if (userData.role === "ADMIN") {
                AppService.accountType = "admin";
            }
            setUser(userData);
            return userData;
        } catch (e) {
            if (e.message === "Request aborted") {
                checkAuth();
            } else {
                cleanToken();
            }
            return false;
        }
    };

    const getUser = () => {
        return AppService.getAxios().get(`/api/user`);
    };

    const getShortName = () => {
        if (!user) {
            return "";
        }
        const firstNameChar = user?.firstName.length > 0 ? user?.firstName[0].toUpperCase() : "";
        const lastNameChar = user?.lastName.length > 0 ? user?.lastName[0].toUpperCase() : "";
        return `${firstNameChar}${lastNameChar}`;
    };

    const signup = async (userToCreate) => {
        return await createUser(userToCreate).then((resp) => {
            return resp;
        });
    };

    const logout = () => {
        AppService.getAxios()
            .delete("/api/user/logout")
            .then(() => {
                cleanToken();
            });
    };

    const createUser = async (userToCreate) => {
        try {
            cleanToken();
            return await AppService.getAxios()
                .post("/auth/signup", userToCreate)
                .then((loginResponse) => {
                    saveToken(loginResponse.data.accessToken);
                    return AppService.getAxios().get(`/api/user`);
                })
                .then((getUserResponse) => {
                    setAuthorized(true);
                    setUser(getUserResponse.data);
                    return getUserResponse.data;
                });
        } catch (err) {
            AppService.errorCode = err.response.data.code;
            AppService.errorMessage = err.response.data.message;
            throw err;
        }
    };

    const changePassword = (oldPassword, newPassword) => {
        return AppService.getAxios()
            .patch("/api/user/password", {
                oldPassword,
                newPassword
            })
            .catch(() => {
                return false;
            });
    };
    const setPortalStatus = (portalStatus) => {
        const userForUpdate = user;
        userForUpdate.portalStatus = portalStatus;
        setUser(cloneDeep(userForUpdate));
    };

    const login = async (email, password) => {
        let result;
        cleanToken();
        const response = await AppService.getAxios().post("/auth/login", {
            email,
            password
        });
        saveToken(response.data.accessToken);
        if (getToken()) {
            await checkAuth();
            result = true;
        }
        return result;
    };

    const saveProfile = (profile) => {
        return AppService.getAxios().put("/api/user", profile);
    };

    const uploadProfileImage = (image) => {
        const formData = new FormData();
        formData.append("file", image);
        return AppService.getAxios().post("/api/user/avatar", formData);
    };
    const value = React.useMemo(
        () => ({
            user,
            authorized,
            getToken,
            setUser,
            saveToken,
            signup,
            checkAuth,
            saveProfile,
            getShortName,
            getUser,
            login,
            cleanToken,
            logout,
            uploadProfileImage,
            changePassword,
            setPortalStatus,
            isSurveyFinished,
            userSurveyStatus,
            setUserSurveyStatus
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
