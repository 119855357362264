import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./SurveyProfileAside.scss";
import MultiSelectDropDownComponent from "@components/multiSelectDropDown/MultiSelectDropDownComponent";
import DropDown from "@components/dropDowns/dropDown/DropDownComponent";
import { States } from "@helper/Constants";
import { AuthContext } from "../../../AuthContext";
import AppService from "../../../service/AppService/AppService";
import { getFilters } from "../../../requests/filterAPIRequest";

function SurveyProfileAside({ onChange, filterParams, setFilterParams, setFiltersApplied }) {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [cities, setCities] = useState([]);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        getFilters()
            .then((response) => {
                setFilters(response.data);
            })
            .catch(() => console.log("Filters not found"));
    }, []);

    const prepareData = (values, key) => {
        const preparedValues = values.map((item) => item.text);
        if (setFilterParams) {
            setFilterParams((prev) => ({ ...prev, [key]: preparedValues }));
        }
    };

    const onChangeCity = (value) => {
        const cityValues = value?.text?.split(",");
        setFilterParams((prev) => ({ ...prev, city: cityValues[0] }));
    };

    const onChangeDonorId = (item, e) => {
        if (e.target.value.match(`^[0-9]*$`) != null && e.target.value.length <= 9) {
            setFilterParams((prev) => ({ ...prev, [item.filterKey]: e.target.value }));
        }
    };

    const onChangeTextFilter = (item, e) => {
        let text = e.target.value;
        setFilterParams((prev) => ({ ...prev, [item.filterKey]: text }));
    };

    const onChangeCityInput = (city) => {
        if (city.length === 0) {
            onChangeCity({ text: "" });
        }
        setFilterParams((prev) => ({ ...prev, city: city }));
        getCities(city);
    };

    const getCities = (city) => {
        let cities = ["Cities not found"];
        if (city && city.length > 0) {
            AppService.getAxios()
                .get(`/api/google-maps/predictions/cities?input=` + city)
                .then((response) => {
                    const responseData = response.data;
                    if (responseData && responseData.predictions && responseData.predictions.length > 0) {
                        cities = responseData.predictions.map((prediction) => {
                            return { text: prediction };
                        });
                    }
                    setCities(cities);
                })
                .catch((e) => console.log(e));
        } else {
            setCities(cities);
        }
    };
    const onFilter = () => {
        onChange(filterParams);
        setToggleSidebar(false);
        setFiltersApplied(true);
    };

    const resetAllFilters = () => {
        sessionStorage.removeItem("filter-params");
        setFilterParams({});
        onChange({});
        setToggleSidebar(false);
        setFiltersApplied(false);
    };

    const onBack = () => {
        sessionStorage.removeItem("oneLoadCount");
        sessionStorage.removeItem("pageNumber");
        sessionStorage.removeItem("scrollPosition");
        switch (history.location.pathname) {
            case "/donors":
                history.push("/start");
                break;
            case "/favorite":
            case "/reserved":
                history.push("/donors");
                break;
            default:
                history.goBack();
        }
    };

    const isSelected = (value) => {
        if (value === history.location.pathname) {
            return "selected-item";
        }
        return "";
    };

    const getAdminMenu = () => {
        return (
            user?.role === "ADMIN" && (
                <div className="admin-container">
                    <span className="title">Editor</span>
                    <div
                        className={`questions-title ${isSelected("/app/editor/questions")}`}
                        onClick={() => history.push("/editor/questions")}
                    >
                        <span>Questions</span>
                    </div>
                </div>
            )
        );
    };

    const getSelectedFiltersFromStorage = (item) => {
        return filterParams && filterParams[item.filterKey] ? filterParams[item.filterKey] : [];
    };

    const prepareFilterValues = (key, values) => {
        return key.toLowerCase() === "states" ? States.map((state) => ({ text: state.name })) : values.map((v) => ({ text: v }));
    };

    const getCityInput = (item) => {
        return (
            filterParams && (
                <div className="filter-property" key={item.filterKey}>
                    <span className="title">{item.filterName}</span>
                    <DropDown
                        customClass={"dropdown-city"}
                        items={cities}
                        selectedItem={{ text: filterParams.city }}
                        placeholder={"Please type and select the city"}
                        readOnly={false}
                        onChange={onChangeCity}
                        handleChangeInputText={onChangeCityInput}
                        isArrowHidden={true}
                    />
                </div>
            )
        );
    };

    const getTextFilterInput = (item) => {
        return filterParams && item.filterKey.toLowerCase() !== "donorid" ? (
            <div className="filter-property" key={item.filterKey}>
                <span className="title">{item.filterName}</span>
                <div className="filter-field">
                    <input
                        type="text"
                        placeholder={`Enter ${item.filterName.toLowerCase()}`}
                        className="donor-id-input"
                        value={filterParams[item.filterKey] || ""}
                        onChange={(e) => onChangeTextFilter(item, e)}
                    />
                </div>
            </div>
        ) : (
            filterParams && (
                <div className="filter-property" key={item.filterKey}>
                    <span className="title">{item.filterName}</span>
                    <div className="filter-field">
                        <input
                            type="text"
                            placeholder="Enter Donor Id"
                            className="donor-id-input"
                            value={filterParams[item.filterKey] || ""}
                            onChange={(e) => onChangeDonorId(item, e)}
                        />
                    </div>
                </div>
            )
        );
    };

    const onYesNoCheck = (item, value) => {
        const selected = filterParams[item.filterKey] === value ? "" : value;
        setFilterParams((prev) => ({ ...prev, [item.filterKey]: selected }));
    };

    const isChecked = (item, expected) => {
        return filterParams && expected === filterParams[item.filterKey];
    };

    const getFilterItem = (item) => {
        switch (item.type) {
            case "SELECT":
                return (
                    <div className="filter-property" key={item.filterKey}>
                        <span className="title">{item.filterName}</span>
                        <MultiSelectDropDownComponent
                            className="dropdown"
                            items={prepareFilterValues(item.filterKey, item.filterValues)}
                            placeholder={"Select the " + item.filterName.toLowerCase()}
                            onChange={(selected) => prepareData(selected, item.filterKey)}
                            selected={getSelectedFiltersFromStorage(item)}
                            toggleSidebar={toggleSidebar}
                        />
                    </div>
                );
            case "TEXT":
                return item.filterKey.toLowerCase() === "city" ? getCityInput(item) : getTextFilterInput(item);
            case "YES_NO":
                return (
                    <div className="filter-property" key={item.filterKey}>
                        <span className="title">{item.filterName}</span>
                        <div className="yes-no-box">
                            <label key={item.filterKey + "-yes"}>
                                <input
                                    type="checkbox"
                                    className="yes-no-checkbox yes-checkbox"
                                    checked={isChecked(item, "Yes")}
                                    name="checkbox"
                                    onChange={() => {
                                        onYesNoCheck(item, "Yes");
                                    }}
                                />
                                Yes
                            </label>
                            <label key={item.filterKey + "-no"}>
                                <input
                                    type="checkbox"
                                    className="yes-no-checkbox no-checkbox"
                                    checked={isChecked(item, "No")}
                                    name="checkbox"
                                    onChange={() => {
                                        onYesNoCheck(item, "No");
                                    }}
                                />
                                No
                            </label>
                        </div>
                    </div>
                );
        }
    };

    return (
        <>
            <div className="survey-profile-aside">
                <div className="back-container" onClick={onBack}>
                    <span className="back">Back</span>
                </div>
                <div className="filter-container">
                    <span className="title">Filters</span>
                    <span className="filter-btn" onClick={() => setToggleSidebar(true)}>
                        Add new filter
                    </span>
                </div>
                {user?.role !== "ADMIN" && (
                    <div className="selected-container">
                        <span className="title">Selected</span>
                        <div className={`favourite-title ${isSelected("/app/favorite")}`} onClick={() => history.push("/favorite")}>
                            <span>Favorites</span>
                            <span className="favourite-count">{user?.favoriteCount}</span>
                        </div>
                        <div className={`reserve-title ${isSelected("/app/reserved")}`} onClick={() => history.push("/reserved")}>
                            <span>Reserved</span>
                            <span className="reserve-count">{0}</span>
                        </div>
                    </div>
                )}
                {getAdminMenu()}
            </div>
            <div className={toggleSidebar ? "sidebar-overlay open" : "sidebar-overlay"}>
                <div className={toggleSidebar ? "sidebar open" : "sidebar"}>
                    <div className="header">
                        <span className="title">Filter</span>
                        <span className="close-button" onClick={() => setToggleSidebar(false)} />
                    </div>
                    <div className="content">
                        {filters.map((filterItem) => {
                            return getFilterItem(filterItem);
                        })}
                        <div className="filter-property">
                            <button className="reset-button" onClick={resetAllFilters}>
                                Clear all filters
                            </button>
                        </div>
                    </div>
                    <div className="footer">
                        <span className="filter-btn bg-parent" onClick={onFilter}>
                            Filter
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SurveyProfileAside;
