import React, { useContext, memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "./TimelineComponent.scss";
import { SurveyQuestionsContext } from "@src/SurveyQuestionsStore";
import AppService from "@service/AppService/AppService";
import { useTranslation } from "react-i18next";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import classNames from "classnames";
import { AuthContext } from "../../AuthContext";
import { UserSurveyStatus } from "../../helper/Constants";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";

function TimelineComponent({ surveyIndex, questions, title, className, disabled, selectedTopic, changeSurveyAndSection }) {
    const context = useContext(SurveyQuestionsContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const { t } = useTranslation();

    const changeJourney = (item) => {
        switch (item.title) {
            case "Application":
                history.push("/survey");
                break;
            case "Matching":
                history.push("/donors");
                break;
        }
    };

    const isSubSurveyHideByDisq = useMemo(() => {
        if (window.location.pathname === "/survey" && AppService.userSurveyStatus.includes(UserSurveyStatus.REJECTED)) {
            const lastPassedSubSurvey = context?.subSurveys?.findIndex((subSurvey) => subSurvey.show === "If not disqualified");
            return lastPassedSubSurvey !== -1 ? surveyIndex >= lastPassedSubSurvey : false;
        }
        return false;
    }, [context, surveyIndex]);

    const onItemClick = (item, index, isSelected) => {
        if (isSelected && title !== "Journey") {
            return;
        }
        ["Journey", "SURVEY"].includes(title) ? changeJourney(item) : changeSurveyAndSection(item, index, surveyIndex);
    };

    const items = useMemo(
        () =>
            questions.map((item, index) => {
                const isSelected = (() => {
                    if (selectedTopic === null) {
                        return false;
                    }
                    if (surveyQuestionMethods.isEdOrIpAccountType() || authContext.user?.role === "ADMIN") {
                        return selectedTopic?.id === item.id;
                    }
                    return selectedTopic?.id === item.sequenceId || selectedTopic?.sequenceId === item.sequenceId;
                })();

                const description = !item.description ? item.estimate : item.description;
                const estimate = !item.description ? "" : item.estimate;
                return (
                    <li className={classNames({ active: isSelected })} key={index} onClick={() => onItemClick(item, index, isSelected)}>
                        <div className={"title"}>
                            {item.title === "Application" ? t("Application") : getTranslatedTextIfNeeded(item.title || item.name)}
                            <span>{estimate}</span>
                        </div>
                        <div className="estimate">{description}</div>
                    </li>
                );
            }),
        [questions, selectedTopic, t, onItemClick]
    );

    const classNameTimeline = useMemo(
        () =>
            classNames("app-survey-timeline", {
                hidden: authContext.isSurveyFinished() ? isSubSurveyHideByDisq : context?.currentSubSurvey < surveyIndex,
                finished: context?.finishedSurveys.includes(surveyIndex) && !authContext.isSurveyFinished()
            }),
        [authContext, context, surveyIndex, isSubSurveyHideByDisq]
    );
    const classNameUl = useMemo(() => classNames(className, { disabled: disabled, enabled: !disabled }), [className, disabled]);

    return (
        <div className={classNameTimeline}>
            <div className="app-survey-timeline-header">{title === "Journey" ? t("Journey") : title}</div>
            <ul className={classNameUl}>{items}</ul>
        </div>
    );
}

export default memo(TimelineComponent);
